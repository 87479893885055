///
/// Inverse by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Header */

	$accent: invert;

	#header {
		@include vendor('display','flex');
		@include vendor('align-items','center');
		@include vendor('justify-content','space-between');
		background: _palette(accent1);
		color: _palette($accent, fg-bold);
		cursor: default;
		padding: 1rem 2rem;
		position: relative;
		text-align: center;
		width: 100%;
		z-index: _misc(z-index-base) + 1;

		> .logo {
			color: _palette(accent2);
			font-size: 1rem;
			font-weight: _font(weight-bold);
			height: inherit;
			line-height: inherit;
			padding: 0;
			text-decoration: none;

			span {
				color: _palette($accent, accent2);
				
			}
		}

		&.alt {
			padding: 2rem;
		}

		> nav {
			margin: 0;

			> ul {
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					margin: 0 .15rem;
					position: relative;
					text-align: center;
					font-size: 16px;
    				font-weight: 600;

					> a {
						@include vendor('transition', 'box-shadow #{_duration(transition)} ease-in-out');
						border-radius: _size(border-radius);
						box-shadow: none;
						color: _palette( accent2);
						overflow: hidden;
						padding: .5rem 1rem;
						position: relative;
						text-decoration: none;

						&:hover {
							color: _palette(fg-bold);
							border-color: _palette(fg-bold);
							box-shadow: inset 0 0 0 2px 
						}

						&.dropdown {
							padding-left: 2.5rem;

							&:before {
								@include vendor('transition', 'background-image #{_duration(transition)} ease-in-out');
								@include vendor('transition', 'opacity #{_duration(transition)} ease-in-out');
								@include vendor('transform', 'rotate(90deg)');
								background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='96' height='96'><style>line {stroke: #fff;stroke-width: 5px;}</style><line x1='32' y1='16' x2='64' y2='48' /><line x1='32' y1='80' x2='64' y2='48' /></svg>");
								background-position: center;
								background-repeat: no-repeat;
								background-size: 100%;
								content: '';
								cursor: pointer;
								height: 100%;
								left: 1rem;
								line-height: inherit;
								position: absolute;
								top: 0;
								width: 1rem;
							}

							&:hover {
								&:before {
									background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='96' height='96'><style>line {stroke: #fff;stroke-width: 5px;}</style><line x1='32' y1='16' x2='64' y2='48' /><line x1='32' y1='80' x2='64' y2='48' /></svg>");
								}
							}
						}
					}

					> ul {
						display: none;
					}

					&.active {
						> a {
							color: _palette($accent, fg-bold);
						}
					}
				}
			}
		}

		@at-root {
			.dropotron {
				background-color: lighten(_palette(accent1),5);
				border-radius: 0.5rem;
				list-style-type: none;
				margin: -0.5rem 0 0 1px;
				min-width: 10rem;
				padding: 0.5rem 0;

				&.level-0 {
					margin-top: 1.125rem;
					margin-left: 0;

					&:before {
						@include vendor('transform', 'rotate(45deg)');
						background-color: lighten(_palette(accent1),5);
						content: '';
						height: 0.75rem;
						position: absolute;
						right: 2rem;
						top: -0.375rem;
						width: 0.75rem;
					}
				}

				li {
					border-top: solid 1px _palette($accent, border);
					padding: 0;
					position: relative;

					&:first-child {
						border-top: none;
					}

					&.active > a {
						color: _palette($accent, fg-bold);
					}
				}

				a {
					border: 0;
					color: _palette($accent, fg);
					padding: 0.5rem 1rem;
					text-decoration: none !important;

					&:active, &:hover, &:focus {
						color: transparentize(_palette($accent, fg-bold), 0.35);
					}
				}
			}
		}

		> .navPanelToggle {
			@include vendor('transition', (
				'background-color #{_duration(transition)} ease-in-out',
				'color #{_duration(transition)} ease-in-out',
				'top #{_duration(transition)} ease-in-out',
				'right #{_duration(transition)} ease-in-out'
			));
			@include icon;
			color: _palette($accent, fg);
			display: none;
			height: inherit;
			line-height: inherit;
			outline: 0;
			padding: 0 1rem;
			position: absolute;
			right: 0;
			top: 0;

			&:hover {
				color: _palette($accent, fg-bold);
			}

			&:before {
				content: '\f0c9';
				float: right;
				margin: 0 0 0 0.625rem;
			}
		}

		@include breakpoint('<=large') {
			padding: 1rem 2rem;
		}

		@include breakpoint('<=medium') {
			@include vendor('display','flex');
			@include vendor('align-items','center');
			@include vendor('flex-direction','column');
			@include vendor('justify-content','center');
			height: 60px;
			line-height: 60px;
			padding: 0;

			&.alt {
				height: auto;
				padding: 3rem 0;

				h1 {
					margin-bottom: 0;
					margin-top: 1.25rem;
				}
			}

			> nav {
				display: none;
			}

			> .navPanelToggle {
				display: block;
				right: 1rem;

				span {
					display: none;
				}
			}

			> .logo {
				font-size: 1rem;
				line-height: 60px;
				left: 1.5rem;
				padding: 0;
				position: absolute;
				top: 0;
			}
		}

		@include breakpoint('<=small') {
			height: 44px;
			line-height: 44px;

			&.alt {
				padding: 1.5rem 0;
			}

			> .navPanelToggle {
				right: 0;
			}

			> .logo {
				line-height: 44px;
				left: 1rem;
			}
		}

		@include breakpoint('<=xsmall') {
			.logo {
				span {
					display: none;
				}
			}
		}
	}