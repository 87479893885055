///
/// Inverse by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.5s,
		transition:			0.2s
	);

// Size.
	$size: (
		border-radius:		4px,
		element-height:		3.25rem,
		element-margin:		1rem,
		container-width:	80rem,
		header-height:		3.25rem,
		menu-width:			20rem
	);

// Font.
	// @import url('https://fonts.googleapis.com/css?family=Oswald:300,400|Tinos');

  @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,400;0,700;1,400;1,700&family=Barlow:ital,wght@0,400;0,600;1,400;1,600&family=Montserrat&display=swap');



	$font: (
		family-title:		('Barlow Condensed', sans-serif),
		family: 			('Barlow', sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				400,
		weight-bold:		600
	);

// Palette.
	$palette: (
		bg:					#F6E6EF,
		bg-alt:				#fafafa,
		fg:					#293D85,
		fg-bold:			#293D85,
		fg-light:			#bbbbbb,
		border:				rgba(144,144,144,0.25),
		border-bg:			rgba(144,144,144,0.075),

		accent1:			#F6E6EF,
		/*
		accent2:			#681542,
		accent3: 			#882F61,
		*/
		accent2:			#3FA497,
		accent3: 			#293D85,

		invert: (
			bg:				#000000,
			fg:				#ffffff,
			fg-bold:		#ffffff,
			fg-light:		rgba(255,255,255,0.4),
			border:			rgba(255,255,255,0.25),
			border-bg:		rgba(255,255,255,0.075),
			highlight:		accent1
		)
	);