///
/// Inverse by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Spotlights */

	.spotlights {
		@include vendor('display','flex');
		@include vendor('align-items','flex-start');
		@include color('invert');
		background: none;
		margin-bottom: _size(element-margin);

			> div {
				> :last-child {
					margin-bottom: 0;
				}
			}

		.spotlight {
			//box-shadow: 0px 15px 59px -20px rgba(0,0,0,0.35);
			margin-left: _size(element-margin) * 8;
			width: 75%;
			min-width:30%;

			.image {
				box-shadow: 0px 30px 80px -1px rgba(240,85,34,0.35);
				border-radius: _size(border-radius) _size(border-radius) 0 0;
				margin: 0;

				img {
					border-radius: _size(border-radius) _size(border-radius) 0 0;
					box-shadow: 0px 15px 59px -20px rgba(0,0,0,0.35);
				}
			}

			.content {
				background: _palette(accent1);
				border-radius: 0 0 _size(border-radius) _size(border-radius);
				font-weight: _font(weight-bold);
				padding: 1.25rem 1rem;
				text-align: center;
			}
		}

		@include breakpoint('<=medium') {
			@include vendor('flex-direction','column');

			> div {
				margin-bottom: _size(element-margin) * 2.5;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.intro {
				text-align: center;

				h2 {
					br {
						display: none;
					}
				}
			}

			.spotlight {
				margin-left: 0;
				width: 100%;

				img {
					height: 25rem;
					object-fit: cover;
				}
			}

			ul {
				&.actions {
					@include vendor('justify-content', 'center');
				}
			}
		}

		@include breakpoint('<=small') {
			> div {
				margin-bottom: _size(element-margin);
			}

			.spotlight {
				img {
					height: 20rem;
				}
			}
		}
	}