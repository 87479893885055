#header>.navPanelToggle, a.close, div#navPanel a.link.depth-0 {color:#E06E8B !important;}

.wrapper {
	@include padding(6rem, 0);

	&.home_bg {
		//background: url('/images/home_bg_2020.jpg');
		background: url('/images/home_bg_2025.jpg');
		background-size: cover;
	}
}

.float-right{
	float:right;
	display:block;
}

.float-left{
	float:left;
}
.apoios{
	// height:150px;
	width:100%;
	// margin: 3em 0 3em 0;
}

/*COLUNAS*/

.flexbox {
        display: flex;
        flex-wrap: wrap;
}

// .box>* {
//     flex: 1 1 160px;
// }

.flexbox > .element{
	flex: 1 1 160px;	
}

// Hide CTA on Homepage
a[id*="teatroinfancia"]{
	display: none;
}

a[id="20200307caramel"],a[id="20200307eagoraondevamos"],a[id="20200307cafarnaum"]{
	display: none;
}
// CATEGORIA (bruno)
strong > span.category{color:_palette(fg-bold) !important;}

div.image.main > img, #headerimage, #headerimage > img {
	max-height: 400px;
	width: auto;
	float: right;
	margin: 0 auto;
}

div.image.main > #headercopyright {
	float:right
}
#main > p > a,
#main > div > div > div > div > p > a {
	color: _palette(fg-bold) !important;
	text-decoration: underline;
	// color: #FF0000;
}
// Buttons

a.button.border{
	border:2px solid;
	border-radius: 3px;
	border-color: _palette(fg-bold);
}
.wrapper.home_bg.special{
	text-shadow: 1px 1px 20px rgba(0,0,0,0.1);
	color: _palette(accent3);
	font-weight: 700;
	
	h1 {
        color: _palette(accent2);
    }
	
}

.single figure, figure#headerimage{
	margin: 10px 15px 15px 15px;
}
figcaption {
	font-size: 12px;
	margin: 0px 10px 20px 0px;
	padding: 0px;
	background-color: transparent !important;
	max-width: 410px;
}
// legenda das imagens
ul.actions  a.button.primary{color:_palette(accent2)  !important;}

// subscrever
form input[type=submit] {
	border:1px solid;
}